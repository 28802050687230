<template>
  <div class="container">
    <div class="insetCenter">
      <div class="block">
<!--        <EditTitle max-length="10"-->
<!--                   v-model:title="title"-->
<!--                   v-model:mode="mode"-->
<!--                   :upload-fn="changeTitle"-->
<!--        />-->
      </div>
      <div class="block">
        <EditTitle
            max-length="10"
            :title="title"
            v-model:mode="mode"
        :upload-fn="changeTitle"/>
      </div>
      <p>{{title}}</p>
    </div>
  </div>
</template>

<script>
import EditTitle from "../../components/EditTitle";
import {colorLog} from "../../util";
export default {
  name: "TestTitleEditor",
  components: {EditTitle},
  data(){
    return {
      title:'测试标题',
      mode: false,
    }
  },
  methods:{
    changeTitle(val){
      colorLog.blue('changeTitle',val);
    }
  }
}
</script>

<style scoped>

</style>
